.page__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 238px;
  padding: 25px 22px;
}

@media (min-width: 530px) {
  .page__form {
    width: 358px;
    padding: 34px 36px 37px;
  }
}
