.footer__copyright {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 16.94px;
  color: #545454;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .footer__copyright {
    font-size: 18px;
    line-height: 21.78px;
    padding-bottom: 60px;
  }
}
