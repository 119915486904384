.footer {
  font-family: 'Inter', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  display: flex;
  justify-content: flex-start;
  width: 88%;
}

@media (min-width: 925px) {
  .footer {
    width: 100%;
  }
}
