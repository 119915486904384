.page__form-submit-btn_theme_black-box {
  background-color: #fff;
  color: #000;
  font-size: 16px;
  line-height: 19px;
  grid-row: 3;
}

@media (min-width: 530px) {
  .page__form-submit-btn_theme_black-box {
    font-size: 18px;
    line-height: 22px;
  }
}
