.profile__name {
  font-weight: 500;
  font-size: 27px;
  line-height: 32.68px;
  margin: 0;
  padding: 0 10px 9px 0;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

@media (min-width: 768px) {
  .profile__name {
    max-width: 294px;
    font-size: 42px;
    line-height: 48px;
    padding: 0 19px 9px 0;
  }
}
