.profile__description {
  font-size: 14px;
  line-height: 16.94px;
  width: 88%;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 336px;
}

@media (min-width: 768px) {
  .profile__description {
    font-size: 18px;
    line-height: 21.78px;
    text-align: left;
  }
}
