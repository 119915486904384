.profile__add-button {
  width: 282px;
  height: 50px;
  padding: 0;
  background-image: url(../../../images/add_button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
}

@media (min-width: 768px) {
  .profile__add-button {
    width: 150px;
    background-size: 22px 22px;
    margin-right: 27px;
  }
}

@media (min-width: 925px) {
  .profile__add-button {
    margin: 0;
  }
}
