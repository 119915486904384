.page__form-text {
  width: 100%;
  height: 27px;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-size: 14px;
  line-height: 16.94px;
  padding-bottom: 3px;
  margin-bottom: 3px;
  color: black;
}
