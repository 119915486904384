.page__container {
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  position: relative;
  cursor: default;
}
