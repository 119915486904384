.popup__select {
  position: relative;
  top: -18px;
  bottom: 100%;
  font-size: 14px;
  line-height: 16.94px;
  appearance: none;
  color: black;
  background-color: white;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  padding: 0 0 3px 0;
  margin: 0 0 3px 0;
  height: 27px;
  text-align: left;
  width: 100%;
}
