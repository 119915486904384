.popup__photo-description {
  position: absolute;
  left: 0;
  bottom: -24px;
  color: #fff;
  font-size: 12px;
  line-height: 14.52px;
  padding: 0;
  margin: 0;
}
