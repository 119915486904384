.page__form-title {
  padding: 0 0 72px;
  margin: 0;
  font-weight: 900;
  font-size: 18px;
  line-height: 21.78px;
  color: #000;
  width: 100%;
}

@media (min-width: 530px) {
  .page__form-title {
    font-size: 24px;
    line-height: 29.05px;
    padding: 0 0 44px;
  }
}
