.page__form-title_theme_black-box {
  font-size: 20px;
  line-height: 24.2px;
  padding: 0 0 35px;
  text-align: center;
  color: #fff;
  grid-row: 1;
}

@media (min-width: 530px) {
  .page__form-title_theme_black-box {
    padding: 0 0 46px;
    font-size: 24px;
    line-height: 29.05px;
  }
}
