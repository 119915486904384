.page {
  background-color: #000;
  color: #fff;
  font-family: 'Inter', Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 320px;
  max-width: 880px;
}
