.profile__edit-button {
  width: 18px;
  height: 18px;
  background-color: #000;
  background-image: url(../../../images/edit_button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 7.5px 7.5px;
  border: 1px solid #fff;
  outline: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: inline-flex;
}

@media (min-width: 768px) {
  .profile__edit-button {
    width: 24px;
    height: 24px;
    background-size: 10px 10px;
  }
}
