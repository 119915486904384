.popup__container-close-btn {
  padding: 0;
  margin: 0;
  width: 38px;
  height: 38px;
  border: none;
  outline: none;
  background-color: #000;
  background: no-repeat center url(../../../images/x_close_button.svg);
  position: absolute;
  top: -50px;
  right: 0;
  cursor: pointer;
}

@media (min-width: 530px) {
  .popup__container-close-btn {
    width: 60px;
    height: 60px;
    right: -50px;
    background-size: 60px 60px;
  }
}
