.profile {
  font-family: 'Inter', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 282px;
  padding-bottom: 36px;
}

@media (min-width: 768px) {
  .profile {
    max-width: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
  }
}
