.place__number-of-likes {
  font-family: 'Inter', Arial, sans-serif;
  font-size: 13px;
  line-height: 15.73px;
  color: #000;
  padding: 2px 0 0;
  margin: 0 8px 0 0;
  grid-column-start: 18;
  grid-row: 19;
  justify-self: center;
}
