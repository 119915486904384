.header {
  font-family: 'Inter', Arial, sans-serif;
  box-sizing: border-box;
  width: 100%;
  min-height: 84px;
  padding: 0 30px 0;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(84,84,84,.7);
}

@media (min-width: 768px) {
  .header {
    padding: 45px 30px 40px;
    margin-bottom: 35px;
  }
}

@media (min-width: 925px) {
  .header {
    padding: 45px 0 40px;
  }
}
