.header__logo {
  width: 103.74px;
  height: 24.4px;
}

@media (min-width: 425px) {
  .header__logo {
    width: calc(27px * 4.3);
    height: 28px;
  }
}

@media (min-width: 768px) {
  .header__logo {
    width: 142px;
    height: 33px;
  }
}
