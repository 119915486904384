.profile__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0 0 33px;
}

@media (min-width: 768px) {
  .profile__info {
    align-items: flex-start;
  }
}
